import Banner from "@/components/Banner";
import React, { useRef, useEffect } from 'react';
import './index.scss'
import i1 from "@/assets/home/1.jpg"
import i2 from "@/assets/home/2.jpg"
import i3 from "@/assets/home/3.jpg"
import i4 from "@/assets/home/4.jpg"
// import wechat from "@/assets/wechat.png"
import { useInView } from "framer-motion"
import { useSelector } from 'react-redux';
function Section({ children }) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <section ref={ref}>
            <span
                style={{
                    transform: isInView ? "none" : "translateX(-200px)",
                    opacity: isInView ? 1 : 0,
                    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                }}
            >
                {children}
            </span>
        </section>
    );
}
function Home() {
    const com = useSelector((state) => state.company.com);
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    useEffect(() => {
        // console.log("Element is in view: ", isInView)
    }, [isInView])
    return (
        <div className="bg-zinc-800">
            {/* <div className="loading_b animate__animated animate__fadeOut animate__delay-2s">
                <div className="loading">LOADING...</div>
            </div> */}


            <Banner></Banner>
            <div className="home_card1 bg-zinc-800  w-full overflow-hidden">
                <div className="content max-w-7xl mx-auto p-4 md:mx-4">
                    <Section>
                        <div className='title text-4xl md:text-3xl  text-center'>业务介绍</div>

                        <div className="desc_b mx-auto mt-5 w-80"></div>
                    </Section>

                    <Section>
                        <div className="other my-14 md:my-6 grid grid-cols-4 md:grid-cols-1 gap-20 md:gap-6">
                            <div className="item">
                                <div className="label text-xl">软件开发</div>
                                <div className="value">
                                    软件销售、软件开发、网络与信息安全软件开发、人工智能应用软件开发、云计算装备技术服务、
                                </div>
                            </div>
                            <div className="item">
                                <div className="label text-xl">信息技术</div>
                                <div className="value">
                                    5G通信技术服务、信息技术咨询服务、信息系统运行维护服务、财务咨询、数据处理和存储支持服务
                                </div>
                            </div>
                            <div className="item">
                                <div className="label text-xl">人工智能</div>
                                <div className="value">
                                    工业机器人安装、维修;工业机器人销售;人工智能硬件销售;智能机器人销售;包装专用设备销售;物料搬运装备销售智能物料搬运装备销售
                                </div>
                            </div>
                            <div className="item">
                                <div className="label text-xl">计算机硬件</div>
                                <div className="value">
                                    信息安全设备销售、互联网设备销售、计算机软硬件及辅助设备零售、信息系统集成服务、电子产品销售、光通信设备销售、工业控制计算机及系统销售
                                </div>
                            </div>
                        </div>
                    </Section>
                </div>
            </div>
            <div className="home_card3 w-full overflow-hidden">
                <div className="content max-w-7xl mx-auto pt-16 md:mx-4 md:py-4">
                    <Section>
                        <div className='title text-4xl md:text-3xl  text-center'>销售与服务</div>
                        <div className='desc text-lg py-10 mx-8 md:mx-0 md:py-4'>
                            公司在不断发展的过程中逐渐建立起一套完善的营销网络及售后服务、客户在这里可以得到售前技术咨询、售中合理化方案和售后标准化服务等一整套完善的服务支持，从而最大限度的满足用户的需求。在坚持技术创新的基础上，狠抓质量管理，不断提高服务水平，实现了公司业务的良性发展。实力不断提升，集人才、技术、资金于一身，形成了“守法、责任、诚信、务实、团结、进取”的企业精神，逐渐成为内蒙古独树一帜的建设者领跑者。
                        </div>
                        <div className="desc_b mx-auto w-80"></div>
                    </Section>
                    <Section>
                        <div className="other my-14 mx-8 md:mx-0 md:my-6 grid grid-cols-4 md:grid-cols-1 gap-20 md:gap-6">
                            <div className="item">
                                <div className="icon mx-auto w-full overflow-hidden">
                                    <img src={i1} alt="" className="w-full rounded-xl duration-200 hover:scale-150"></img>
                                </div>
                                <div className="label text-2xl text-center my-4">全流程跟踪服务</div>
                                <div className="value">
                                    拥有完善的营销网络及售后服务、客户在这里可以得到售前技术咨询、售中合理化方案和售后标准化服务等一套完善的服务支持，最大限度的满足用户的需求。
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon mx-auto w-full overflow-hidden">
                                    <img src={i2} alt="" className="w-full rounded-xl duration-200 hover:scale-150"></img>

                                </div>
                                <div className="label text-2xl text-center my-4">软件定制服务</div>
                                <div className="value">
                                    拥有专业的技术团队及成熟产品开发经验，对公司现有软件产品可根据用户需求进行特别定制，包含各类功能，各种界面等需求进行开发。
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon mx-auto w-full overflow-hidden">
                                    <img src={i3} alt="" className="w-full rounded-xl duration-200 hover:scale-150"></img>

                                </div>
                                <div className="label text-2xl text-center my-4">高效的响应速度</div>
                                <div className="value">
                                    公司团队实力强劲且与多方合作伙伴联系密切，对各类项目响应速度及时，建设效率高，拥有齐全的配套服务，最大化利用资源，节省时间提高效率。
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon mx-auto w-full overflow-hidden">
                                    <img src={i4} alt="" className="w-full rounded-xl duration-200 hover:scale-150"></img>
                                </div>
                                <div className="label text-2xl text-center my-4">优质的服务理念</div>
                                <div className="value">
                                    我们公司秉承客户至上、服务至上的经营理念，以卓越的服务品质、专业的技术服务实力、技术精湛的客户服务团队，最大化保障客户的效益。
                                </div>
                            </div>
                        </div>
                    </Section>
                </div>
            </div>
            {/* <div className="home_card3 w-full overflow-hidden">
                <div className="content max-w-7xl mx-auto pt-16 md:mx-4 md:py-4">
                    <Section>
                        <div className='title text-4xl md:text-3xl  text-center'>客户案例</div>

                        <div className="desc_b mx-auto w-80"></div>
                    </Section>
                    <Section>
                        <div className="other my-14 mx-8 md:mx-0  md:my-6 grid grid-cols-2 md:grid-cols-1 gap-20 md:gap-6">
                            <div className="item">
                                <div className="icon mx-auto w-full overflow-hidden">
                                    <img src={i1} alt="" className="w-full rounded-xl duration-200 hover:scale-150"></img>
                                </div>
                                <div className="label text-2xl text-center my-4">达拉特经济开发区数字化封闭管理平台建设项目
                                </div>
                                <div className="value">
                                    本项目主要利用数字化信息平台、视频监控、在线监测预警等技术，建立完善的园区门禁系统、视频监控系统，实现封闭化管理，对出入园区的车辆进行统一管理，对进入园区车辆进行报备管理。车辆进入园区时，会有对应的车道引导指示牌和地标道路提醒字体引导车辆行驶，按照危化及货车类别行驶，采用道闸、视频抓拍方式对车辆进行管理，最终实现卡口无人值守方式，并达到了“减人、提效、增安”的目标，实现全面园区的封闭式管理。
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon mx-auto w-full overflow-hidden">
                                    <img src={i2} alt="" className="w-full rounded-xl duration-200 hover:scale-150"></img>

                                </div>
                                <div className="label text-2xl text-center my-4">土默特右旗园区化工区封闭化管理建设工程</div>
                                <div className="value">
                                    本项目运用了封闭式管理平台，保障监管信息、数据准确无误，让园区道闸卡口、视频监控、物联网等各管理软件结合的方式真正发挥出实效，形成园区立体、全面、严谨的封闭管理网，相关数据和智慧园区相贯穿，营造了园区安全有序的生产环境。最终实现了对进出园区的车辆管控，准确掌握了进出园区车辆的数据和流量，强化了园区进出动态监管，有效保障了园区生产安全，促进了安全园区的建设。
                                </div>
                            </div>

                        </div>
                    </Section>
                </div>
            </div>
            <div className="home_card2 w-full overflow-hidden">
                <div className="content max-w-7xl mx-auto pt-16 md:mx-4 md:py-4">
                    <Section>
                        <div className='title text-4xl md:text-3xl  text-center'>产品介绍</div>

                        <div className="desc_b mx-auto w-80"></div>
                    </Section>
                    <Section>
                        <div className="other my-14 mx-8 md:mx-0 md:my-6 grid grid-cols-5 md:grid-cols-1 gap-20 md:gap-6">
                            <div className="item">
                                <div className="icon mx-auto w-14 h-14">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
                                    </svg>
                                </div>
                                <div className="label text-2xl text-center my-4">弱电项目</div>
                                <div className="value">
                                    电力电缆的铺设，光纤的传导，监控的安装，已形成一套完整的服务体系，为多个厂区完成弱电相关的安装工作。
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon mx-auto w-14 h-14">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" w-14 h-14">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                                    </svg>
                                </div>
                                <div className="label text-2xl text-center my-4">视频监控</div>
                                <div className="value">
                                    拥有成熟的软件系统及技术支持，在视频监控领域开展多个项目，软件硬件相结合，达成准确及时的图像传递及数据处理。
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon mx-auto w-14 h-14">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                    </svg>
                                </div>
                                <div className="label text-2xl text-center my-4">小程序开发</div>
                                <div className="value">
                                    根据相关的定制要求，开发小程序，在微信界面快捷方便的达成信息传递，工作交接，审核评价等功能。
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon mx-auto w-14 h-14">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                                    </svg>
                                </div>
                                <div className="label text-2xl text-center my-4">财务系统</div>
                                <div className="value">
                                    用友软件的销售，财务软件领域的开拓，是公司多元化发展的趋势，已和多家企业达成合作，拥有成熟的财务知识及软件支持。
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon mx-auto w-14 h-14">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819" />
                                    </svg>
                                </div>
                                <div className="label text-2xl text-center my-4">园区封闭化管理</div>
                                <div className="value">
                                    根据管委会要求，对划分的工业园区进行封闭化管理，包括道闸安装，监控联动，二维码小程序报备信息，是多个模块集成的封闭化管理项目。
                                </div>
                            </div>
                        </div>
                    </Section>
                </div>
            </div> */}

            <div className="home_card1 bg-zinc-800 w-full overflow-hidden">
                <div className="content max-w-7xl mx-auto p-4  mt-5 md:mx-4 md:py-4">
                    <Section>
                        <div className='title text-4xl md:text-3xl  text-center'>公司简介</div>
                        <div className="desc_b mx-auto mt-5 w-80"></div>
                        <div className='desc text-lg py-10 md:py-4'>
                            {com.intro}
                        </div>

                    </Section>

                </div>
            </div>
        </div>
    );
}

export default Home;