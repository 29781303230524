import { createSlice } from '@reduxjs/toolkit';
import { fetchCompanyData } from './companyActions';
const initialState = {
    com: {},
    status: 'idle',
    error: null,
};

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCompanyData.fulfilled, (state, action) => {
            state.com = action.payload;
            state.status = 'succeeded';
        });
        builder.addCase(fetchCompanyData.rejected, (state, action) => {
            state.error = action.error.message;
            state.status = 'failed';
        });
    },
});

export default companySlice.reducer;