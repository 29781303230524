
import { useLocation } from 'react-router-dom'
import { request, cos } from "@/until/request";
import { useState, useEffect, React } from "react"

import './single.scss'
function Single() {
    const searchParams = new URLSearchParams(useLocation().search);
    const id = searchParams.get('id');
    const [art, setArt] = useState([])
    useEffect(() => {
        const getData = async () => {
            const res = await request({ url: `/home/get_single/${id}` })
            let data = res.data
            data.content = content_format_add(data.content)
            setArt(data)
        }
        getData()

    }, [id])
    const content_format_add = (content) => {
        if (content) { //编辑器内容
            // // 拼上域名
            content = content.replaceAll(new RegExp(`(/uploads/)|(/${cos}/uploads/)`, 'g'), function (str) {
                return cos + str;
            });

        } else {
            content = '';
        }
        return content;
    }
    return (
        <div className="acticle_detail max-w-5xl mx-auto  p-8 md:w-full md:px-3 bg-slate-50 rounded-xl">
            <div className="title text-3xl md:text-2xl">{art.title}</div>
            {/* <div className="time text-lg text-slate-400 my-2">{art.updated_at}</div> */}
            <div className="cover">
                <img src={cos + art.cover} alt="" className="rounded-xl" />
            </div>
            <div className="content py-4" dangerouslySetInnerHTML={{ __html: art.content }} />
        </div>
    );
}

export default Single;