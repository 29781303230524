
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { request, cos } from "@/until/request";
import { useState, useEffect } from "react"
import './list.scss'
function List() {
    const navigate = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const id = searchParams.get('id');
    const sid = searchParams.get('sid') ? parseInt(searchParams.get('sid')) : 0;

    const [cateInfo, setCateInfo] = useState([])
    const [artList, setArtList] = useState([])
    useEffect(() => {
        const getData = async () => {
            const res = await request({ url: `/home/get_data/${id}/${sid}` })
            // console.log(res.data)
            setCateInfo(res.data.cateInfo)
            setArtList(res.data.artList)
        }
        getData()

    }, [id, sid])
    const onSubMenuClick = (obj, e) => {

        e.stopPropagation(true)
        // if (obj.pid === 0) {
        //     navigate('/list?id=' + id)
        //     return
        // }
        // if (obj.type === 1) {
        //     navigate('/single?id=' + obj.singleid)
        // } else {
        //     navigate('/list?id=' + obj.pid + '&sid=' + obj.id)
        // }
        let targetPath;
        if (obj.pid === 0) {
            targetPath = `/list?id=${id}`;
        } else if (obj.type === 1) {
            targetPath = `/single?id=${obj.singleid}`;
        } else {
            targetPath = `/list?id=${obj.pid}&sid=${obj.id}`;
        }

        // 阻止事件冒泡
        // event.stopPropagation();
        // console.log(location.pathname + location.search, targetPath);
        // 检查目标路径是否与当前路径相同
        if (location.pathname + location.search === targetPath) {

            window.location.reload(); // 刷新当前页面
        } else {
            navigate(targetPath, { replace: true }); // 导航至新路径并替换历史记录（可选）
        }
    }
    const go_art = (id, e) => {
        e.stopPropagation(true)
        navigate('/detail?id=' + id)
    }
    return (
        <>
            <div className="list_head mx-48 my-10 rounded-xl  overflow-hidden md:hidden">
                <div className="top p-10 grid grid-cols-6 gap-4">
                    <div className="cover col-span-1">
                        <img src={cos + cateInfo.cover} className=" rounded-xl" alt="" />
                    </div>
                    <div className="info col-span-5">
                        <div className="title text-3xl mb-5">{cateInfo.name}</div>
                        <div className="desc text-base">{cateInfo.desc}</div>
                    </div>
                </div>
                {cateInfo?.children?.length > 0 && <div className="bottom flex flex-wrap items-center">
                    <div className={classNames('item', sid === 0 ? 'active' : '')} onClick={(event) => onSubMenuClick({ pid: 0 }, event)}>全部</div>
                    {cateInfo.children.map(item => {
                        return <div className={classNames('item', item.id === sid ? 'active' : '')} key={item.id} onClick={(event) => onSubMenuClick(item, event)}>{item.name}</div>
                    })}
                </div>}

            </div>
            <div className="list_head_m mx-4 my-4 hidden md:block ">
                <div className="block p-4 rounded-xl">
                    <div className="top rounded-xl grid grid-cols-2 gap-4 pb-2">
                        <div className="cover col-span-1">
                            <img src={cos + cateInfo.cover} className=" rounded-xl" alt="" />
                        </div>
                        <div className="info col-span-1">
                            <div className="title text-2xl mb-5">{cateInfo.name}</div>
                        </div>
                    </div>
                    <div className="desc text-base">{cateInfo.desc}</div>

                </div>
                {cateInfo?.children?.length > 0 &&
                    <div className="bottom flex flex-wrap items-center">
                        <div className={classNames('item', sid === 0 ? 'active' : '')} onClick={(event) => onSubMenuClick({ pid: 0 }, event)}>全部</div>
                        {cateInfo.children.map(item => {
                            return <div className={classNames('item', item.id === sid ? 'active' : '')} key={item.id} onClick={(event) => onSubMenuClick(item, event)}>{item.name}</div>
                        })}
                    </div>
                }

            </div>
            <div className="list_content mx-48 my-10 md:mx-4 md:my-4   rounded-xl grid grid-cols-3 gap-6">
                {artList.map(item => {
                    return <div className="item col-span-1 md:col-span-3 p-6 rounded-xl" key={item.id} onClick={(event) => go_art(item.id, event)}>
                        <div className="cover">
                            <img src={cos + item.cover} className=" rounded-xl" alt="" />
                        </div>
                        <div className="title ">{item.title}</div>
                        {/* <div className="time">{item.updated_at}</div> */}
                    </div>
                })}

            </div >
        </>
    );
}

export default List;