import { configureStore, combineReducers } from '@reduxjs/toolkit';
import companyReducer from './companyReducer';

const rootReducer = combineReducers({
    company: companyReducer,
    // 其他reducers...
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(),
});

export default store;