import i1 from "@/assets/about/1.jpg"
import i2 from "@/assets/about/2.jpg"
import i3 from "@/assets/about/3.jpg"
import i5 from "@/assets/about/5.jpg"
import i6 from "@/assets/about/6.jpg"
import './index.scss'
function About() {
    return (
        <div className="about_ bg-zinc-800 w-full">
            <div className="max-w-5xl mx-auto  p-8 md:w-full md:px-3 ">
                <div className="grid grid-cols-2 md:grid-cols-1 gap-20">
                    <div>
                        <img src={i1} alt="" className="rounded-xl"></img>
                    </div>
                    <div>
                        <h1 className="text-2xl font-bold mb-4">公司介绍</h1>
                        <p>内蒙古云大互联网科技有限责任公司成立于2008年11月17日，注册资金300万元，公司坐落在内蒙古包头市昆都仑区财富中心17层，是一家从事软件销售、软件开发、视频监控、系统集成等业务的公司。先后承接过包头市土默特右旗化工园区和鄂尔多斯市达拉特旗封闭化工园区以及智慧园区的建设、达茂联合旗全旗等多家企事业单位的办公OA系统以及财务管理系统的定制、开发与集成，是包头地区最具规模和实力的IT企业之一。在软件开发设计领域，公司有专业的软件开发人员，专门为客户定制开发专业软件系统。满足不同领域客户对软件的应用需求。以高度的使命感、责任感一如既往的为用户和社会提供满意的产品和服务，将公司做大做强，创造新的业绩，展示新的风采，为中国信息产业发展做出贡献。
                        </p>
                    </div>
                </div>
                <div className="my-8">
                    <div className="text-2xl font-bold mb-4">文化理念</div>
                    <div>
                        <img src={i3} alt="" className="rounded-xl"></img>
                    </div>
                </div>

                <div className="my-8">
                    <div className="text-2xl font-bold mb-4 text-right">公司风采</div>
                    <div>
                        <img src={i2} alt="" className="rounded-xl"></img>
                    </div>
                </div>
                <div className="my-8">
                    <div className="text-2xl font-bold mb-4">发展前景</div>
                    <div>
                        <img src={i5} alt="" className="rounded-xl"></img>
                    </div>
                </div>

                <div className="my-8">
                    <div className="text-2xl font-bold mb-4 text-right">竞争优势</div>
                    <div>
                        <img src={i6} alt="" className="rounded-xl"></img>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;