import { createHashRouter } from "react-router-dom";
import Layout from "@/pages/Layout";
import List from "@/pages/List";
import Detail from "@/pages/Detail";
import About from "@/pages/About";
import Single from "@/pages/Single";
import Home from "@/pages/Home";
import Error from "@/pages/Error";
const router = createHashRouter([
    {
        path: '/',
        element: <Layout></Layout>,
        children: [
            {
                index: true,
                element: <Home></Home>,
            },
            {
                path: '/list',
                element: <List></List>
            },
            {
                path: '/detail',
                element: <Detail></Detail>
            },
            {
                path: '/about',
                element: <About></About>
            },
            {
                path: '/single',
                element: <Single></Single>
            },
        ],
    },
    {
        path: '/404',
        element: <Error></Error>
    }
])
export default router