import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import './banner.scss'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { cos } from "@/until/request";
import { useBannerList } from "@/hooks/useBannerList";
// import i1 from "@/assets/banner/b1.jpg"
// import i2 from "@/assets/banner/b2.jpg"
// import i3 from "@/assets/banner/b3.jpg"

// import i4 from "@/assets/banner/4.jpg"
// import i5 from "@/assets/banner/5.jpg"


function Banner() {
    const { bannerList } = useBannerList()
    // console.log(bannerList)
    return (
        <div className='wrap  overflow-hidden relative -top-14 md:top-0'>
            <Swiper className='banner' modules={[Navigation, Pagination, Autoplay]} navigation pagination={{ clickable: true }}
                autoplay={{
                    delay: 5000, // 自动切换间隔时间（单位：毫秒）
                    disableOnInteraction: true, // 用户操作后是否停止自动播放，默认为true
                }}
                loop
            >
                {bannerList.map(item => {
                    return (
                        <SwiperSlide className='item' key={item.id}> <img className='image' alt='' src={cos + item.path}></img> </SwiperSlide>
                    )
                })}
            </Swiper>
            {/* <div className='home-recommend relative z-10 -top-10 bg-transparent  xl:top-0 xl:bg-white block md:hidden'>
                <div className='card rounded-lg flex items-center justify-between bg-white max-w-7xl mx-auto px-5 py-5 xl:mx-4 '>
                    <div className="nav flex items-center cursor-pointer w-1/5">
                        <div className="icon flex items-center justify-center w-12 h-12">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="svg w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
                            </svg>

                        </div>
                        <div className="right ml-4 h-12">
                            <div className="title text-base">系统集成</div>
                            <div className="desc text-sm truncate">综合布线</div>
                        </div>
                    </div>
                    <div className="nav flex items-center cursor-pointer w-1/5">
                        <div className="icon flex items-center justify-center w-12 h-12">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="svg w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                            </svg>

                        </div>
                        <div className="right ml-4 h-12">
                            <div className="title text-base">系统互联</div>
                            <div className="desc text-sm truncate">各类软件数据互通</div>
                        </div>
                    </div>
                    <div className="nav flex items-center cursor-pointer w-1/5">
                        <div className="icon flex items-center justify-center w-12 h-12">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="svg w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                            </svg>

                        </div>
                        <div className="right  ml-4 h-12">
                            <div className="title text-base">数字孪生</div>
                            <div className="desc text-sm truncate">智慧工厂 社区 园区</div>
                        </div>
                    </div>
            
                    <div className="nav flex items-center cursor-pointer w-1/5">
                        <div className="icon flex items-center justify-center w-12 h-12">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="svg w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5" />
                            </svg>
                        </div>
                        <div className="right ml-4 h-12">
                            <div className="title text-base">软件开发</div>
                            <div className="desc text-sm truncate">PC APP 公众号</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='home-recommend relative z-10 hidden md:block'>
                <div className='card flex items-center justify-between bg-white '>
                    <div className="nav w-1/4 py-1">
                        <div className="icon_m mx-auto flex items-center justify-center w-8 h-8">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="svg w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
                            </svg>

                        </div>
                        <div className="title_m text-base text-center">系统集成</div>
                    </div>
                    <div className="nav w-1/4">
                        <div className="icon_m mx-auto flex items-center justify-center  w-8 h-8">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="svg w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                            </svg>

                        </div>
                        <div className="title_m text-base text-center">系统互联</div>
                    </div>
                    <div className="nav w-1/4">
                        <div className="icon_m mx-auto flex items-center justify-center  w-8 h-8">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="svg w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                            </svg>

                        </div>
                        <div className="title_m text-base text-center">数字孪生</div>
                    </div>
                    
                    <div className="nav w-1/4">
                        <div className="icon_m mx-auto flex items-center justify-center  w-8 h-8">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="svg w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5" />
                            </svg>
                        </div>
                        <div className="title_m text-base  text-center">软件开发</div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default Banner;