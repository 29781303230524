import { useState, useEffect } from "react"
import { request } from "../until/request";
function useNavList() {
    const [navList, setNavList] = useState([])
    useEffect(() => {
        const getNavList = async () => {
            const res = await request({ url: '/home/nav' })

            setNavList(res.data)
        }
        getNavList()
    }, [])
    return {
        navList
    }
}
export { useNavList }