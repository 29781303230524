

import React, { useState, Fragment } from 'react'
import classNames from 'classnames'
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom'
import { useNavList } from "@/hooks/useNavList";
import { cos } from "@/until/request";
import { useSelector } from 'react-redux';

function PcNav({ list }) {

    const com = useSelector((state) => state.company.com);
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const path = location.pathname + "?id=" + searchParams.get('id')
    const onMenuClick = (obj, e) => {
        if (obj.path === location.pathname + location.search) {
            window.location.reload(); // 对于当前页面进行刷新
            return;
        }
        if (obj.path === '/') {
            navigate('/')
            return
        }
        if (obj.type === 1) {
            navigate('/single?id=' + obj.singleid)
        } else {
            navigate('/list?id=' + obj.id)
        }
    }
    const onSubMenuClick = (obj, e) => {
        e.stopPropagation();
        let targetPath;
        if (obj.type === 1) {
            // navigate('/single?id=' + obj.singleid)
            targetPath = `/single?id=${obj.singleid}`
        } else {
            targetPath = `/list?id=${obj.pid}&sid=${obj.id}`

        }
        if (location.pathname + location.search === targetPath) {

            window.location.reload(); // 刷新当前页面
        } else {
            navigate(targetPath, { replace: true }); // 导航至新路径并替换历史记录（可选）
        }
    }

    return (

        <div className="sm:hidden">
            <div className="pc_nav">
                <div className="flex justify-between">
                    <h1 className="w-20 h-14 flex justify-center items-center">
                        <img src={cos + com.logo} alt="" className="w-10 h-10"></img>
                    </h1>
                    <ul className="max-w-7xl w-3/5 flex justify-between items-center">
                        <li className={classNames('h-14', 'px-1.5', 'cursor-pointer', 'group', 'text-neutral-50')} onClick={(event) => onMenuClick({ path: "/", type: 0 }, event)} >
                            <div className="h-1"></div>
                            <div className="h-12 flex  items-center ">首页</div>
                            <div className={classNames('w-full', 'h-1', 'rounded', path === '/?id=null' ? 'bg-white' : 'bg-transparent')}></div>
                        </li>
                        {list.map((item, index) => {
                            return (
                                <li key={item.id} className={classNames('menu-item', 'relative', 'h-14', 'px-1.5', 'cursor-pointer', 'group', 'text-neutral-50')} onClick={(event) => onMenuClick(item, event)}
                                >
                                    <div className="h-1"></div>
                                    <div className="h-12 flex  items-center ">{item.name}</div>
                                    <div className={classNames('w-full', 'h-1', 'rounded', path === item.path ? 'bg-white' : 'bg-transparent')}></div>
                                    {item.children.length > 0 && (
                                        <div className="sub-menu absolute z-10 -left-full w-48 rounded">
                                            {item.children.map((subItem, subIndex) => (
                                                <div
                                                    key={subIndex}
                                                    onClick={(event) => onSubMenuClick(subItem, event)}
                                                    className="item block text-gray-700  px-4 py-2 rounded "
                                                >
                                                    {subItem.name}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                    <div></div>
                </div>

            </div>


        </div>
    )
}
function MobileNav({ list }) {
    const com = useSelector((state) => state.company.com);
    //手机菜单显示
    const [mobileMenu, setMobileMenu] = useState(false)
    const set_menu = () => {
        setMobileMenu(!mobileMenu)
    }
    //手机菜单展开
    const [subMobileMenu, setSubMobileMenu] = useState(0)
    const open_mobile_sub_menu = (id, e) => {
        e.stopPropagation(true)
        if (id === subMobileMenu) {
            setSubMobileMenu(0)
            return
        }
        setSubMobileMenu(id)
    }
    const navigate = useNavigate()
    const onMenuClick = (obj, e) => {
        e.stopPropagation(true)
        setSubMobileMenu(0)
        setMobileMenu(false)
        if (obj.path === '/') {
            navigate('/')
            return
        }
        if (obj.type === 1) {
            navigate('/single?id=' + obj.singleid)
        } else {
            navigate('/list?id=' + obj.id)
        }
    }
    const onSubMenuClick = (obj, e) => {

        e.stopPropagation(true)
        setSubMobileMenu(0)
        setMobileMenu(false)

        if (obj.type === 1) {
            navigate('/single?id=' + obj.singleid)
        } else {
            navigate('/list?id=' + obj.pid + '&sid=' + obj.id)
        }
    }
    return (
        <div className="hidden sm:block">
            <div className="m_nav flex justify-between ">
                <h1 className="w-20 h-14 flex justify-center items-center">
                    <img src={cos + com.logo} alt="" className="w-10 h-10"></img>
                </h1>
                <div className="w-20 h-14 flex justify-between items-center pr-4" onClick={set_menu}>
                    <div className="">菜单</div>
                    {
                        mobileMenu ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    }
                </div>
            </div>
            {mobileMenu &&
                <div className="w-full h-dvh fixed flex justify-end top-14 z-20" onClick={set_menu}>
                    <div className="inset-x-1/4 w-9/12 h-dvh bg-white border-t-2 border-t-zinc-100">
                        <div className="mx-6 py-4 m_item">
                            <div className="il w-3/4 h-full" onClick={(event) => onMenuClick({ path: "/", type: 0 }, event)}>首页</div>
                        </div>
                        {list.map(item => {
                            return (
                                <Fragment key={item.id}>
                                    <div className="mx-6 py-4 m_item">
                                        <div className="il w-3/4 h-full" onClick={(event) => onMenuClick(item, event)}>{item.name}</div>
                                        {item.children.length > 0 && <div className="ir w-1/4 h-full flex justify-end" onClick={(event) => open_mobile_sub_menu(item.id, event)}>
                                            {subMobileMenu === item.id ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                                            </svg>
                                                : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                                </svg>}

                                        </div>}

                                    </div>
                                    {(item.children.length > 0 && subMobileMenu === item.id) && <div className="w-full grid grid-cols-2 m_sub_wrap">
                                        {item.children.map(e => {
                                            return (
                                                <div className="m_sub_nav" key={e.id} onClick={(event) => onSubMenuClick(e, event)}>

                                                    <div className="text">{e.name}</div>
                                                </div>
                                            )
                                        })}

                                    </div>}
                                </Fragment>
                            )

                        })}

                    </div>
                </div>
            }
        </div>
    )
}
function Nav() {
    const { navList } = useNavList()
    for (let i of navList) {
        let str = i.type === 0 ? '/list?id=' : '/single?id='
        let id = i.type === 0 ? i.id : i.singleid
        i.path = str + id
    }
    return (
        <div className={classNames('sticky', 'top-0', 'z-30', 'h-14', 'bg-zinc-800', 'shadow-xl', 'sm:bg-white')}>
            <PcNav list={navList}></PcNav>
            <MobileNav list={navList}></MobileNav>
        </div>
    );
}

export default Nav;