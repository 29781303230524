import { useState, useEffect } from "react"
import { request } from "../until/request";
function useBannerList() {
    const [bannerList, setBannerList] = useState([])
    useEffect(() => {
        const getBannerList = async () => {
            const res = await request({ url: '/home/banner' })

            setBannerList(res.data)
        }
        getBannerList()
    }, [])
    return {
        bannerList
    }
}
export { useBannerList }